import constants from "constants/constants";

export const AFFLIATE_DISCOUNT_TYPE_ENUM = {
  percentage: 1,
  absolute: 2,
};

export const AFFLIATE_DISCOUNT_TYPE = [
  { label: "Amount", value: AFFLIATE_DISCOUNT_TYPE_ENUM.absolute },
  { label: "Percentage", value: AFFLIATE_DISCOUNT_TYPE_ENUM.percentage },
];
export const AFFLIATE_DISCOUNT_CURRENCY = [
  { label: "INR", value: constants.inr },
  { label: "USD", value: constants.usd },
];
export const AFFLIATE_APPLICABILITY = [
  { label: "All approved offerings of the affiliate", value: 1 },
  { label: "Specific approved offerings of the affiliate", value: 2 },
];
