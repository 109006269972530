import React from "react";
import ExlyModal from "common/Components/ExlyModal";
import { Form } from "react-final-form";
import styles from "./AffliateDiscountModal.module.css";
import AddAffliateDiscount from "../AddAffliateDiscount";
import { useAddAffliateDiscount } from "../utils/useAddAffliateDiscount";
import { useShowDiscountCodesModal } from "../../AffliateDiscount/utils/useShowDiscountCodesModal";
import { useGetAffiliateUuid } from "features/AffiliateMarketing/utils/useGetAffiliateUuid";
import { DISCOUNT_TYPE } from "../../AffliateDiscount/constants/affliateDiscount.constants";
import { AFFLIATE_DISCOUNT_TYPE_ENUM } from "../constants/affliateDiscount.constant";

const AddAffliateDiscountModal = () => {
  const { affiliateUuid } = useGetAffiliateUuid();
  const handleClose = (form) => {
    form.reset();
    closeDiscountCodesModal();
  };
  const { closeDiscountCodesModal, showDiscountCodesModal } =
    useShowDiscountCodesModal();
  const initialValues = {
    type: DISCOUNT_TYPE.PERCENTAGE,
    affiliate_uuid: affiliateUuid,
  };
  const { handleAddAffliateDiscountCode, processing } =
    useAddAffliateDiscount();

  const validateValues = (values) => {
    const errors = {};
    if (values.type === AFFLIATE_DISCOUNT_TYPE_ENUM.percentage) {
      if (parseInt(values.usage_limit) > 1 && parseInt(values.value) > 50) {
        errors.value = "Max 50% discount value for usage limit more than 1";
      }
    }
    return errors;
  };

  return (
    <>
      <Form
        validate={validateValues}
        onSubmit={handleAddAffliateDiscountCode}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting }) => (
          <ExlyModal
            open={showDiscountCodesModal}
            onClose={() => handleClose(form)}
            title={"Create Affiliate Discount Code"}
            onPrimaryBtnClick={handleSubmit}
            primaryBtnText={processing ? processing : "Create Affiliate Code"}
            primaryBtnProps={{
              type: "submit",
              disabled: submitting,
              loading: submitting,
            }}
            modal_props={{
              modalPaperClassName: styles.desktopModalPaper,
            }}
            mobile_modal_props={{}}
            className={`p-0`}
          >
            <div className={`px-3`}>
              <form onSubmit={handleSubmit}>
                <AddAffliateDiscount />
              </form>
            </div>
          </ExlyModal>
        )}
      />
    </>
  );
};

export default AddAffliateDiscountModal;
