import React from "react";
import ExlyModal from "common/Components/ExlyModal";
import moduleStyle from "./PayoutModal.module.css";
import classnames from "classnames";
import { Button } from "@my-scoot/component-library-legacy";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import ExlyLoader from "ui/modules/ExlyLoader";
import constants from "constants/constants";
import { usePayment } from "features/AffiliateMarketing/utils/usePayment";
import { usePayoutModal } from "./utils/usePayoutModal";
import { useBankDetail } from "features/AffiliateMarketing/utils/useBankDetail";
import { useGetAffiliateUuid } from "features/AffiliateMarketing/utils/useGetAffiliateUuid";
import { roundOff } from "utils/Utils";
import { RUPEE_SYMBOL } from "features/Common/modules/Currency/constants/Currency.constants";
import ExlyImage from "features/Common/modules/ExlyImage/ExlyImage";

const PayoutModal = () => {
  const { initiatePayment, getTransactionInvoiceUrl } = usePayment();
  const { handleSendRemainder } = useBankDetail();
  const { affiliateUuid } = useGetAffiliateUuid();

  const {
    payoutData,
    processing,
    showAffiliatePayoutModal,
    showAffiliatePayoutErrorModal,
    handleShowAffiliatePayoutModal,
    handleShowAffiliatePayoutErrorModal,
  } = usePayoutModal();

  if (showAffiliatePayoutErrorModal) {
    return (
      <ExlyModal
        open={showAffiliatePayoutErrorModal}
        title="Pay Commission"
        onClose={() => handleShowAffiliatePayoutErrorModal(false)}
        onPrimaryBtnClick={() => handleSendRemainder(affiliateUuid)}
        showSecondaryBtn={true}
        primaryBtnText={processing ? "..." : "Send Reminder"}
        primaryBtnProps={{
          className: classnames(moduleStyle.sendRemainder),
        }}
        modal_props={{
          modalPaperClassName: moduleStyle.modalWrapper,
        }}
        className={`p-0`}
      >
        <div className={moduleStyle.container}>
          {processing ? (
            <ExlyLoader />
          ) : (
            <>
              <ExlyImage
                path={"assets/images/affiliates/bank.svg"}
                className={moduleStyle.payoutImg}
              />
              <span className={moduleStyle.heading}>
                Affiliate bank details not available!
              </span>
              <span className={moduleStyle.subHeading}>
                Affiliate has not added/updated their bank details.
              </span>
              <span className={moduleStyle.subHeading}>
                Please inform them to update it
              </span>
            </>
          )}
        </div>
      </ExlyModal>
    );
  }

  if (showAffiliatePayoutModal)
    return (
      <ExlyModal
        open={showAffiliatePayoutModal}
        title="Pay Commission"
        onClose={() => handleShowAffiliatePayoutModal(false)}
        onPrimaryBtnClick={() =>
          initiatePayment({ requestId: payoutData?.request_id })
        }
        showSecondaryBtn={true}
        primaryBtnText={processing ? "..." : "Confirm"}
        primaryBtnProps={{
          className: classnames(moduleStyle.primaryBtn),
        }}
        modal_props={{
          modalPaperClassName: moduleStyle.modalWrapper,
        }}
        className={`p-0`}
      >
        <div className={moduleStyle.container}>
          {processing ? (
            <ExlyLoader />
          ) : (
            <>
              {" "}
              <ExlyImage
                path={"assets/images/affiliates/payout.svg"}
                className={moduleStyle.payoutImg}
              />
              <div
                className={`d-flex align-items-center ${moduleStyle.heading}`}
              >
                <span>{`Pay Affiliate Commission of ${
                  constants.CURRENCY_SYMBOL_MAP[payoutData.currency] ||
                  RUPEE_SYMBOL
                } ${roundOff(payoutData.final_gateway_amount, 2)}`}</span>
              </div>
              <span className={moduleStyle.subHeading}>
                The commissions will get transferred to the affiliate&apos;s
                bank account
              </span>
              <div className={moduleStyle.buttons}>
                <Button
                  color="link"
                  className={moduleStyle.secondaryButton}
                  buttonWrapperClassName={moduleStyle.buttonWrapperClassName}
                  variant="text"
                  onClick={() =>
                    getTransactionInvoiceUrl({
                      affiliateUuid,
                      payoutAmount: payoutData.affiliate_payout_amount,
                    })
                  }
                >
                  <div className={`d-flex align-items-center`}>
                    <VisibilityOutlinedIcon className={moduleStyle.icons} />
                    <div>View Invoice</div>
                  </div>
                </Button>
              </div>
            </>
          )}
        </div>
      </ExlyModal>
    );

  return null;
};

export default PayoutModal;
